import Typography from '@mui/material/Typography';
import CustomContainer from '../CustomContainer/CustomContainer';
import Box from '@mui/material/Box';
import classes from './SegmentResults.module.css';
import { buildApiUrl } from '../../../services/api';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

export async function fetchSegmentResults(segmentId: number) {
    try {
        const response = await fetch(buildApiUrl(`/segment-result/leaderboard/${segmentId}`), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch payment history');
        }

        const data: Result[] = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch payment history:', error);
        return [];
    }
}

type Result = {
    id: number;
    firstName: string;
    lastName?: string;
    averagePower?: number;
    time: number;
    resultDate: string;
    wattsPerKg: number;
};
function SegmentResults() {
    const [results, setResults] = useState<Result[]>([]);

    const segmentId = 2;
    const loadResults = async () => {
        const data = await fetchSegmentResults(segmentId);
        setResults(data);
    };

    useEffect(() => {
        loadResults();
    }, [segmentId]);

    const formatTime = (timeInTenths: number): string => {
        const totalSeconds = timeInTenths / 10;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };
    return (
        <section className={classes['segment-results']} aria-labelledby="segment-results_title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['segment-results__title']}
                    id="segment-results"
                >
                    Результаты заездов на сегментах
                </Typography>
                <Box mt={4}>
                    <Typography variant="h3">Лучшие результаты на сегменте «Маяк»</Typography>
                    {results.length === 0 ? (
                        <Typography>Данные о результатах отсутствуют.</Typography>
                    ) : (
                        <>
                            <Table
                                sx={{
                                    display: { xs: 'block', sm: 'table' },
                                    overflowX: 'auto',
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>№</TableCell>
                                        <TableCell>Имя</TableCell>
                                        <TableCell>Фамилия</TableCell>
                                        <TableCell>Время (сек)</TableCell>
                                        <TableCell>Средняя мощность (Вт)</TableCell>
                                        <TableCell>Вт/Кг</TableCell>
                                        <TableCell>Дата попытки</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results.map((result, index) => (
                                        <TableRow key={result.id}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{result.firstName}</TableCell>
                                            <TableCell>{result.lastName}</TableCell>
                                            <TableCell>{formatTime(result.time)}</TableCell>
                                            <TableCell>{result.averagePower || '-'}</TableCell>
                                            <TableCell>{result.wattsPerKg}</TableCell>
                                            <TableCell>
                                                {new Date(result.resultDate).toLocaleDateString()}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </Box>
            </CustomContainer>
        </section>
    );
}

export default SegmentResults;
