import { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import { buildApiUrl } from '../../../../services/api';

export async function fetchSegmentResults(segmentId: number) {
    try {
        const response = await fetch(buildApiUrl(`/segment-result/user/${segmentId}`), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch payment history');
        }

        const data: Result[] = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch payment history:', error);
        return [];
    }
}

type Result = {
    id: number;
    firstName: string;
    lastName?: string;
    averagePower?: number;
    time: number;
    resultDate: string;
};

function CabinetSegments() {
    const [results, setResults] = useState<Result[]>([]);

    const segmentId = 2;
    const loadResults = async () => {
        const data = await fetchSegmentResults(segmentId);
        setResults(data);
    };

    useEffect(() => {
        loadResults();
    }, [segmentId]);

    const formatTime = (timeInTenths: number): string => {
        const totalSeconds = timeInTenths / 10;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <Box mt={4}>
            <Typography variant="h3">Мои лучшие результаты на сегменте «Маяк»</Typography>
            {results.length === 0 ? (
                <Typography>Данные о результатах отсутствуют.</Typography>
            ) : (
                <>
                    <Table
                        sx={{
                            display: { xs: 'block', sm: 'table' },
                            overflowX: 'auto',
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>№</TableCell>
                                <TableCell>Время (сек)</TableCell>
                                <TableCell>Средняя мощность (Вт)</TableCell>
                                <TableCell>Дата попытки</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results.map((result, index) => (
                                <TableRow key={result.id}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{formatTime(result.time)}</TableCell>
                                    <TableCell>{result.averagePower || '-'}</TableCell>
                                    <TableCell>
                                        {new Date(result.resultDate).toLocaleDateString()}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </Box>
    );
};

export default CabinetSegments;
